import { useMe } from "./useMe"

export type Language = "en" | "nl"

export interface TextToTranslation {
  en: string
  nl: string
}

export function useBetterTranslation() {
  const { me } = useMe()

  return (text: TextToTranslation) => text[me?.language || "nl"]
}
