import { gql, useApolloClient } from "@apollo/client"
import { useRouter } from "next/router"

import { ACCESS_TOKEN } from "lib/config"
import { MeDocument, useLogoutMutation } from "lib/graphql"

import { useMutationHandler } from "./useMutationHandler"
import { useToast } from "./useToast"

const _ = gql`
  mutation Logout {
    logout
  }
`

export const useLogout = () => {
  const client = useApolloClient()
  const router = useRouter()
  const toast = useToast()
  const [logout] = useLogoutMutation()
  const handler = useMutationHandler()
  const handleLogout = async () => {
    handler(logout, {
      onSuccess: async () => {
        await router.replace("/logout")
        localStorage.removeItem(ACCESS_TOKEN)
        await fetch("/api/logout", { method: "post" })
        client.writeQuery({ query: MeDocument, data: { me: null } })
        toast({ description: "Successfully logged out!" })
      },
    })
  }
  return handleLogout
}
