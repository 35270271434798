import * as React from "react"
import type { FieldError, FieldErrors, Merge } from "react-hook-form"
import { FormErrorMessage } from "@chakra-ui/react"

interface Props {
  error?:
    | FieldError
    | Merge<FieldError, FieldErrors<any>>
    | string
    | Partial<{
        type: string | number
        message: string
      }>
}

export function InputError(props: Props) {
  if (!props.error) return null
  return (
    <>
      {typeof props.error === "string" ? (
        <FormErrorMessage>{props.error}</FormErrorMessage>
      ) : props.error.message && typeof props.error.message === "string" ? (
        <FormErrorMessage>{props.error.message}</FormErrorMessage>
      ) : (
        props.error?.type &&
        Object.values(props.error?.type).map((error, i) => (
          <FormErrorMessage key={i}>{error}</FormErrorMessage>
        ))
      )}
    </>
  )
}
