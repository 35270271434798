import * as React from "react"
import type { ModalProps } from "@chakra-ui/react"
import {
  Modal as CModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react"

interface Props extends Omit<ModalProps, "onClose"> {
  title?: React.ReactNode
  onClose?: () => void
}
export function Modal(props: Props) {
  const bg = useColorModeValue("white", "gray.800")
  const handleClose = () => {
    return props.onClose?.()
  }
  return (
    <CModal {...props} onClose={handleClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent borderRadius="md" m={4} bg={bg}>
        {props.onClose && <ModalCloseButton />}
        {props.title && <ModalHeader pb={3}>{props.title}</ModalHeader>}
        <ModalBody mb={4}>{props.children}</ModalBody>
      </ModalContent>
    </CModal>
  )
}
